import * as z from "zod";

export const GenerateOtpResponseSchema = z.object({
	status: z.string(),
	session_id: z.string(),
	phone: z.string(),
	digits: z.number()
});

export const PointOfContactSchema = z.object({
	mobile_number: z.string(),
	name: z.string(),
	email: z.string().optional(),
	email_verified: z.boolean().optional()
});

export const OrganisationSchema = z.object({
	is_deleted: z.boolean(),
	_id: z.string(),
	name: z.string(),
	point_of_contact: PointOfContactSchema,
	created_at: z.string(),
	updated_at: z.string(),
	agency_logo: z.string().optional(),
	gst_certificate: z.string().optional(),
	business_registration_certificate: z.string().optional()
});

export const USER_ROLES = z.enum([
	"ADMIN",
	"CX",
	"DATA_ARCHITECT",
	"FINANCE",
	"KAM",
	"MANAGER",
	"VISA_EXPERT",
	"SECTOR_LEAD",
	"VISA_OPS",
	"LOGISTICS_HEAD",
	"LOGISTICS_PARTNER"
]);

export const CurrentUserResponseSchema = z.object({
	is_email_verified: z.boolean(),
	visas_held: z.array(z.string()).optional(),
	is_active: z.boolean(),
	is_deleted: z.boolean(),
	_id: z.string(),
	email: z.string().optional(),
	phone: z.string(),
	organisation_id: OrganisationSchema,
	first_name: z.string(),
	last_name: z.string(),
	created_at: z.string(),
	updated_at: z.string(),
	is_smv_user: z.boolean().optional(),
	roles: z.array(USER_ROLES),
	frontend_app_version_key: z.string()
});

export const AppConfigSchema = z.object({
	metadata: z.record(z.any()),
	updated_at: z.string(),
	version_key: z.string()
});

export const UserSchema = z.object({
	reports_to: z.array(z.string()),
	_id: z.string(),
	email: z.string().optional(),
	phone: z.string(),
	first_name: z.string(),
	last_name: z.string(),
	is_phone_verified: z.boolean().optional(),
	is_email_verified: z.boolean(),
	created_at: z.coerce.date().optional(),
	updated_at: z.coerce.date(),
	organisation_id: z.string(),
	visas_held: z.array(z.any()).optional(),
	created_by: z.string().optional(),
	is_deleted: z.boolean(),
	is_active: z.union([z.boolean(), z.null()]),
	is_migrated: z.boolean().optional(),
	is_smv_user: z.boolean(),
	roles: z.array(USER_ROLES),
	frontend_app_version_key: z.string(),
	is_whatsapp_opt_in: z.boolean().optional(),
	last_login_at: z.coerce.date().optional(),
	__v: z.number().optional(),
	social_login_platform: z.enum(["GOOGLE"]).optional(),
	updated_by_script: z.boolean().optional(),
	middle_name: z.string().optional()
});

export const ReporteesSchema = z.object({
	VISA_OPS: z.array(UserSchema),
	VISA_EXPERT: z.array(UserSchema),
	KAM: z.array(UserSchema),
	SECTOR_LEAD: z.array(UserSchema),
	MANAGER: z.array(UserSchema),
	LOGISTICS_HEAD: z.array(UserSchema),
	LOGISTICS_PARTNER: z.array(UserSchema)
});
