import { CurrencyInr, Hammer, Icon, UserCircle, UserCircleGear, UserSound, UserSquare } from "@phosphor-icons/react";
import { MenuProps } from "antd";
import clsx from "clsx";
import NewChangeLogModal from "components/NewChangeLogModal";
import { capitalizeFirstLetter } from "helpers";
import { getLatestChangelogVersion } from "pages/Changelog/Api";
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PUBLIC_ROUTES } from "routes/Routes";
import { useBoolean } from "usehooks-ts";
import { Mixpanel } from "utils/mixpanel";
import sentry from "utils/sentry";
import { getReportees, validateSession } from "../Api";
import useAppConfig from "../useAppConfig";

declare const window: Window &
	typeof globalThis & {
		clarity: any;
	};

type TAuthContext = {
	user: AuthUser;
	updateUser: React.Dispatch<React.SetStateAction<AuthUser>>;
	isLoading: boolean;
	getSetUserAndNavigate: () => void;
	refreshReportees: () => void;
	reportees: TReportees;
	isReporteesLoading: boolean;
	userRoleMenuItem: MenuProps["items"];
	appConfig: TAppConfig;
	getAppConfig: (version: string) => Promise<TAppConfig | undefined>;
	patchAppConfig: (data: Record<string, any>) => Promise<TAppConfig | undefined>;

	/**
	 * Checks if the user is in the specified roles.
	 * @param roles - Array of roles to check against.
	 * @returns Boolean indicating if the user is in the roles.
	 */
	isUserInRoles: (roles: TUserRoles[] | TUserRoles) => boolean;

	/**
	 * Checks if the user is not in the specified roles.
	 * @param roles - Array of roles to check against.
	 * @returns Boolean indicating if the user is not in the roles.
	 */
	isUserNotInRoles: (roles: TUserRoles[] | TUserRoles) => boolean;
};

export const AuthContext = createContext<TAuthContext | undefined>(undefined);

export const USER_ROLES_MAP: Record<
	TUserRoles,
	{
		label: string;
		icon: Icon;
	}
> = {
	ADMIN: {
		label: "Admin",
		icon: UserCircleGear
	},
	CX: {
		label: "Customer Experience",
		icon: UserSound
	},
	DATA_ARCHITECT: {
		label: "Data Architect",
		icon: Hammer
	},
	FINANCE: {
		label: "Finance",
		icon: CurrencyInr
	},
	KAM: {
		label: "Key Account Manager",
		icon: UserCircle
	},
	MANAGER: {
		label: "Manager",
		icon: UserCircle
	},
	VISA_EXPERT: {
		label: "Visa Expert",
		icon: UserCircle
	},
	SECTOR_LEAD: {
		label: "Sector Lead",
		icon: UserSquare
	},
	VISA_OPS: {
		label: "Visa Ops",
		icon: UserCircle
	},
	LOGISTICS_HEAD: {
		label: "Logistics Head",
		icon: UserCircle
	},
	LOGISTICS_PARTNER: {
		label: "Logistics Partner",
		icon: UserCircle
	}
};

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<AuthUser>(null);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [latestRelease, setLatestRelease] = useState<TRelease | null>(null);
	const { appConfig, getAppConfig, patchAppConfig } = useAppConfig();

	const localUserRole = localStorage.getItem("userRole") as TUserRoles | undefined;

	const [isReporteesLoading, setIsReporteesLoading] = useState(false);
	const [reportees, setReportees] = useState<TReportees>({
		KAM: [],
		VISA_EXPERT: [],
		MANAGER: [],
		SECTOR_LEAD: [],
		VISA_OPS: [],
		LOGISTICS_HEAD: [],
		LOGISTICS_PARTNER: []
	});

	const { value, toggle } = useBoolean();
	const {
		value: openChangeLogModal,
		setTrue: handleOpenChangeLogModal,
		setFalse: handleCloseChangeLogModal
	} = useBoolean();

	const isPublicRoute = () => {
		const currentHash = window.location.hash.replace("#", "");
		return PUBLIC_ROUTES.some((route) => route.path?.startsWith(currentHash));
	};

	const setUserForMetrics = (user: AuthUser) => {
		if (!user) return;

		// Mixpanel & Webengage
		Mixpanel.identify(user._id);
		Mixpanel.people.set({
			$first_name: user.first_name,
			$last_name: user.last_name,
			$email: user.email,
			$phone: user.phone,
			$created: user.created_at,
			Role: user.type
		});
		// Sentry
		sentry.setUser({ ...user, username: user.first_name + " " + user.last_name });

		// Clarity
		window.clarity?.("identify", user._id);
		window.clarity?.("set", "user", user.first_name + " " + user.last_name);
		window.clarity?.("set", "email", user.email);
		window.clarity?.("set", "phone", user.phone);
		window.clarity?.("set", "role", user.type);
	};

	const getSetUserAndNavigate = () => {
		(async () => {
			try {
				setIsLoading(true);
				const res = await validateSession();
				if (res.success) {
					res.data.frontend_app_version_key && (await getAppConfig(res.data.frontend_app_version_key));
					const role =
						localUserRole && res.data.roles.includes(localUserRole) ? localUserRole : res.data.roles[0];
					setUserForMetrics({ ...res.data, type: role });
					setUser({ ...res.data, type: role });
					const redirectTo = searchParams.get("redirectTo");
					if (redirectTo) {
						searchParams.delete("redirectTo");
						navigate(redirectTo);
					}
				}
			} catch (error) {
				if (!isPublicRoute()) navigate("/auth/sign-in");
			} finally {
				setIsLoading(false);
			}
		})();
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(getSetUserAndNavigate, []);

	useEffect(() => {
		if (!appConfig.metadata) return;
		getLatestChangelogVersion().then(({ data }) => {
			if (data.success && data.data.version !== appConfig.metadata?.latestVersion) {
				setLatestRelease(data.data);
				handleOpenChangeLogModal();
			}
		});
	}, [appConfig.metadata, handleOpenChangeLogModal]);

	useEffect(() => {
		if (!user) return;
		localStorage.setItem("userRole", user.type);
		window.clarity?.("identify", user.phone);
		window.clarity?.("set", "User Role", user.type);
		window.clarity?.("set", "User First Name", user.first_name);

		const { _id, ...nUser } = user;
	}, [user]);

	useEffect(() => {
		if (!user) return;
		(async () => {
			try {
				setIsReporteesLoading(true);
				const res = await getReportees(user.type);
				if (res.success) {
					setReportees(
						Object.entries(res.data).reduce(
							(acc, [key, value]) => ({
								...acc,
								[key]: value.sort((a, b) => a.first_name.localeCompare(b.first_name))
							}),
							{
								KAM: [],
								VISA_EXPERT: [],
								MANAGER: [],
								SECTOR_LEAD: [],
								VISA_OPS: [],
								LOGISTICS_HEAD: [],
								LOGISTICS_PARTNER: []
							}
						)
					);
				}
			} finally {
				setIsReporteesLoading(false);
			}
		})();
		return () => {};
	}, [user, value]);

	const handleRoleChange = (role: TUserRoles) => {
		setUser((prev) => {
			if (!prev) return prev;
			return {
				...prev,
				type: role
			};
		});
		window.location.hash = "";
	};

	const handleCloseModal = useCallback(() => {
		handleCloseChangeLogModal();
		patchAppConfig({ latestVersion: latestRelease?.version });
	}, [handleCloseChangeLogModal, latestRelease?.version, patchAppConfig]);

	const userRoleMenuItem: MenuProps["items"] =
		user?.roles?.map((role, index) => {
			const Icon = USER_ROLES_MAP[role]?.icon ?? UserCircle;
			return {
				key: role,
				icon: <Icon size={16} />,
				label: USER_ROLES_MAP[role]?.label ?? capitalizeFirstLetter(role.toUpperCase()),
				onClick: () => handleRoleChange(role),
				className: clsx("!h-8 !flex !items-center !text-[13px] !font-normal !px-4 !py-1.5 !rounded-md")
			};
		}) ?? [];

	const isUserInRoles = useCallback((roles: TUserRoles[] | TUserRoles) => roles.includes(user?.type!), [user?.type]);

	const isUserNotInRoles = useCallback(
		(roles: TUserRoles[] | TUserRoles) => !roles.includes(user?.type!),
		[user?.type]
	);
	return (
		<AuthContext.Provider
			value={{
				user,
				isLoading,
				getSetUserAndNavigate,
				updateUser: setUser,

				refreshReportees: toggle,
				reportees,
				isReporteesLoading,

				isUserInRoles,
				isUserNotInRoles,
				userRoleMenuItem,
				appConfig,
				getAppConfig,
				patchAppConfig
			}}>
			<div
				className={clsx(
					"fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center gap-2 bg-slate-100 transition-opacity duration-300",
					isLoading ? "visible opacity-100" : "pointer-events-none invisible opacity-0"
				)}>
				<span className="relative py-2 animate-bounce ">
					<img
						width={150}
						height={40}
						src="./icons/common/nucleus-logo-full.svg"
						alt="StampMyVisa"
						className=""
					/>
				</span>
			</div>

			<div
				className={clsx(
					"transition-opacity duration-700 ease-5",
					isLoading ? "pointer-events-none invisible opacity-0" : "visible opacity-100"
				)}>
				{children}
			</div>
			{latestRelease && (
				<NewChangeLogModal release={latestRelease} open={openChangeLogModal} onClose={handleCloseModal} />
			)}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error("useAuthContext must be within OrdersProvider");
	}
	return context;
};
